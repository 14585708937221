.color {
  &__select {
    cursor: pointer;

    &__block {
      width: 100%;
      height: 60px;
  }

    input:checked ~ &__block {
      background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))
  }
}
}

