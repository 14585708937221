.select2 {
  &-selection {
    &__choice {
      background-color: #5e72e4 !important;
      color: #f6f9fc !important;

      &__remove {
        margin-left: 0 !important;
        color: #f6f9fc !important;

        &:hover {
          background-color: #f5365c !important;
          color: #f6f9fc !important;
        }
      }

      &__display {
        padding-left: 15px !important;
        padding-right: 0 !important;
      }
    }

    &__clear {
      margin-top: 0 !important;
      margin-right: 5px !important;
    }

    &__rendered {
      padding-left: 0 !important;
      padding-right: 20px !important;
      color: #525252 !important;
      font-weight: 400;
      line-height: 1.5;
      font-size: 0.875rem;
      transition: all 0.15s ease-in-out;
      height: calc(1.5em + 1.25rem + 5px);
    }
  }

  &-results {
    &__option {
      &--highlighted {
        background-color: #5e72e4 !important;
        color: #f6f9fc !important;
      }
    }
  }
}
