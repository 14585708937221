.grid {
  &__image {
    max-width: 250px;
    height: auto;

    &__sm {
      max-width: 150px;
    }
  }

  &__logo {
    max-width: 100px;
    height: auto;

    &__sm {
      max-width: 30px;
      height: auto;
    }
  }

  &__icon {
    border: 0;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    text-transform: none;
    will-change: transform;
  }

  //grid__logo
  //grid__image_sm
}
