.crop {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
  margin: 0 auto;

  &__container {
    min-width: 200px;
    min-height: 200px;
    max-height: 300px;
  }

  &__preview {
    width: 100%;
    height: 100%;
    border-radius: .375rem;
  }

  &__upload {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    transition: all .15s ease;
    text-align: center;
    color: #8898aa;
    background-color: rgba(0, 0, 0, .2);
    order: -1;
    margin: 0;
    border-radius: .375rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.cropped &__upload {
    opacity: 0;
    color: white;
    background-color: rgba(0, 0, 0, .9);
  }

  &__preview__img {
    width: 100%;
    height: 100%;
    border-radius: .375rem;
    object-fit: contain;
  }

  &__buttons {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all .3s ease-in-out;
    opacity: 0;
    pointer-events: none;
    display: flex;
    background-color: rgba(0, 0, 0, .4);
    border-radius: .375rem;

  }

  &:hover &__buttons {
    opacity: 1;
    pointer-events: all;
  }

  label i {
    font-size: 1.5rem;
    box-sizing: content-box;
    vertical-align: middle;
  }

  img[src=''] {
    display: none;
  }
}

// sizes
.user-avatar .crop {
  width: 200px;
  height: 200px;
}

.small-image {
  width: 50px;
}

.medium-image {
  width: 150px;
}

.large-image {
  width: 300px;
}

.small-crop-image .crop {
  max-width: 50px;
  width: 100%;
  height: 50px;
}

.medium-crop-image .crop {
  max-width: 150px;
  width: 100%;
  height: 150px;
}

.large-crop-image .crop {
  max-width: 300px;
  width: 100%;
  height: 300px;
}

.news__cover .crop {
  max-width: 385px;
  width: 100%;
  height: 173px;
}

.category__image .crop {
  max-width: 395px;
  height: 204px;
}

.shop__image .crop {
  max-width: 385px;
  height: 300px;
}

.shop__logo .crop {
  max-width: 385px;
  height: 150px;
}

.managementCompany__logo .crop {
  max-width: 200px;
  height: 200px;
}
